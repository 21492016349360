export default {
  text: {
    light: '#FFFFFF',
    lightGrey: '#414A69',
    lighterGrey: '#F4F4F5',
    dark: '#000000',
    darkGrey: '#2B3144'
  },
  color: {
    primary: '#FBC843',
    secondary: '#FFFFFF'
  }
}
