import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './styles/index.css'
import './styles/typography.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from './styles/theme'
import 'intersection-observer'

const rootElement = document.getElementById('root') as HTMLElement
const render = (Component: any) => {
  return ReactDOM.render(
    <Router>
      <ThemeProvider theme={theme}>
        <Component />
      </ThemeProvider>
    </Router>,
    rootElement
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const HotApp = require('./App').default
    render(HotApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister()
