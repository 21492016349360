import React, { Suspense, lazy, useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import * as images from './assets'

const imageList = Object.values(images)

const Marketing = lazy(() => import('./routes/Marketing'))
const Header = lazy(() => import('./components/Header'))
const Footer = lazy(() => import('./components/Footer'))

const App: React.FC = () => {
  const [loaded, setLoaded] = useState(false)
  const [count, incrementCount] = useState(0)

  useEffect(() => {
    if (count === imageList.length) {
      setLoaded(true)
    }
  }, [count])

  const renderImages = () =>
    imageList.map(src => (
      <img
        key={src}
        src={src}
        alt="img preload"
        onLoad={() => incrementCount(count + 1)}
      />
    ))

  return (
    <Main>
      <Suspense fallback={<LoaderWrapper />}>
        {loaded ? (
          <>
            <Header />
            <Switch>
              <Route exact path="/" component={Marketing} />
            </Switch>
            <Footer />
          </>
        ) : (
          <LoaderWrapper>
            <svg width={130} height={130} viewBox="-5 -10 140 140">
              <g fill="none" fillRule="evenodd">
                <circle
                  stroke="#7B87AF"
                  strokeWidth={2}
                  cx={66}
                  cy={60}
                  r={61}
                />
                <path
                  d="M92.674 52.106l-6.885-3.677a.62.62 0 0 0-.776.186l-6.3 8.977a.851.851 0 0 1-.824.316l-3.883-.909a.838.838 0 0 1-.593-.65l-1.512-10.82a.633.633 0 0 0-.607-.529h-9.568a.635.635 0 0 0-.608.529l-1.55 10.83a.845.845 0 0 1-.594.65l-3.895.915a.855.855 0 0 1-.824-.315l-6.3-8.974a.619.619 0 0 0-.775-.186l-6.854 3.657a.647.647 0 0 0-.295.755l7.495 21.636a.803.803 0 0 0 .707.503h36.555a.8.8 0 0 0 .706-.503L92.97 52.86a.647.647 0 0 0-.295-.755"
                  fill="#7B87AF"
                />
                <circle
                  className="orbiter"
                  fill="#7B87AF"
                  cx={66}
                  cy={60}
                  r={5}
                />
              </g>
            </svg>
            <ImageLoader>{renderImages()}</ImageLoader>
          </LoaderWrapper>
        )}
      </Suspense>
    </Main>
  )
}

const orbit = keyframes`
  0% {
    transform: rotate(0deg) translateX(-61px);
  }

  100% {
    transform: rotate(360deg) translateX(-61px);
  }
`

const ImageLoader = styled.div`
  visibility: hidden;
  height: 0;
  width: 0;
  font-size: 0;
`

const LoaderWrapper = styled.div`
  position: fixed;
  display: flex;
  background: ${({ theme }) => theme.color.secondary};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;

  .orbiter {
    animation-name: ${orbit};
    transform-origin: 50% 50%;
    transform-box: fill-box;
    animation-duration: 2s;
    animation-play-state: running;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
`

const Main = styled.main`
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
`

export default App
