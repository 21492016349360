export const app1 = require('./app-1.png')
export const app2 = require('./app-2.png')
export const app3 = require('./app-3.png')
export const arrowLeft = require('./arrow-left.svg')
export const arrowRight = require('./arrow-right.svg')
export const carWhite1 = require('./car-white-1.png')
export const carWhite2 = require('./car-white-2.png')
export const gaugeIcon = require('./gauge-icon.svg')
export const guy = require('./guy.png')
export const coin = require('./coin.svg')
export const wrench = require('./wrench.svg')
export const document = require('./document.svg')
export const lineGraphIcon = require('./line-graph-icon.svg')
export const logoBlack = require('./logo-black.svg')
export const logoWhite = require('./logo-white.svg')
export const map = require('./map.png')
export const porcheFront = require('./porche-front.png')
export const porscheRight = require('./porsche-right.png')
export const porscheLeft = require('./porsche-left.png')
export const pumpIcon = require('./pump-icon.svg')
export const roverLeft = require('./rover-left.png')
